import MobileArticles from '@/components/Articles'
import Layout from '@/components/Layout'
import TenantInfo from '@/components/TenantInfo'
import React, { Component } from 'react'

export default class forHyresgaster extends Component {
    render() {
        return (
            <Layout active={3} title="För hyresgäster">
                <div className="tenant-info p-margin">
                    <h1 className="tenant-info__heading">Information för dig som hyresgäst.</h1>
                    <TenantInfo/>
                </div>
                <MobileArticles amount={3} bottom={false}/>
            </Layout>
        )
    }
}
