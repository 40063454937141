import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

let data = null;

export default function TenantInfo() {
    if(!data) data = useStaticQuery(graphql`
        query {
            allContentfulForHyresgasterInfo {
                edges {
                    node {
                        title
                        text {raw}
                        node_locale
                        attachment{
                            file {
                              url
                              fileName
                            }
                        }
                    }
                }
            }
        }
    `).allContentfulForHyresgasterInfo.edges.reverse()

    return (
        <div className="p-margin">
            {data.map(edge => (
                <div className="tenant-info__chunk">
                    <h3>{edge.node.title}</h3>
                    {documentToReactComponents(JSON.parse(edge.node.text.raw))}
                    {edge.node.attachment ? (
                        <ul>
                            {edge.node.attachment.map(f => (
                                <li>
                                    <a href={f.file.url} target="_blank" className="tenant-info__attachment">
                                        {f.file.fileName}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    ) : ""}
                </div>
            ))}
        </div>
    )
}
